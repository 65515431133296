<template>
  <div>
    <b-button v-b-modal.add-issue-category-modal>{{ $t("issue-category.add-category") }}</b-button>
    <b-modal
      id="add-issue-category-modal"
      @hidden="clear"
      @ok="addIssueCategory"
      :title="$t('issue-category.add-modal.title')"
      :ok-title="$t('issue-category.add-modal.ok-btn-caption')"
      :cancel-title="$t('issue-category.cancel-btn-caption')"
    >
      <template v-slot:default>
        <b-form-group :label="$t('issue-category.title')">
          <b-form-input v-model.trim="titleModel"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('issue-category.short-title')">
          <b-form-input v-model.trim="shortTitleModel"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('issue-category.checks')">
          <v-select
            multiple
            v-model="selectedChecks"
            :options="customChecks"
            :getOptionLabel="x => `${x.title[$i18n.locale]}`"
          >
            <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
          </v-select>
        </b-form-group>
        <b-form-group :label="$t('issue-category.description')">
          <b-form-textarea v-model.trim="description"></b-form-textarea>
        </b-form-group>
      </template>
    </b-modal>
  </div>
</template>

<script>
import converter from "@/services/converter.js";

export default {
  data() {
    return this.getDefaultData();
  },
  computed: {
    customChecks() {
      return this.$store.state.customChecks.customChecks;
    },
    titleModel: {
      get() {
        return this.title;
      },
      set(value) {
        this.title = value;
        this.shortTitleModel = value.substring(0, 3);
      }
    },
    shortTitleModel: {
      get() {
        return this.shortTitle;
      },
      set(value) {
        this.shortTitle = value.toUpperCase();
      }
    }
  },
  methods: {
    getDefaultData() {
      return {
        title: "",
        shortTitle: "",
        description: "",
        selectedChecks: []
      };
    },
    async addIssueCategory(e) {
      e.preventDefault();

      if (!this.title) {
        alert(this.$t("issue-category.error.empty-title"));
        return;
      }

      if (!this.shortTitle) {
        alert(this.$t("issue-category.error.empty-short-title"));
        return;
      }

      if (this.shortTitle.length > 10 || !new RegExp("^[A-Z]+$").test(this.shortTitle)) {
        alert(this.$t("issue-category.error.invalid-short-title-format"));
        return;
      }

      let title = converter.stringToStringTranslate(this.title, this.$i18n.locale);

      try {
        await this.$store.dispatch("issueCategories/add", {
          title: title,
          description: this.description,
          shortTitle: this.shortTitle,
          customCheckIds: this.selectedChecks.map(x => x.id)
        });
      } catch (err) {
        if (err.data.errorCode) {
          alert(this.$t("issue-category.error." + err.data.errorCode));
        } else {
          alert(this.$t("issue-category.error.while-adding"));
        }
        return;
      }

      this.$bvModal.hide("add-issue-category-modal");
    },
    clear() {
      Object.assign(this.$data, this.getDefaultData());
    }
  },
  mounted() {
    this.$store.dispatch("customChecks/loadData");
  }
};
</script>
