import { EN, RU, LT } from "@/const/langs.js";

export default {
  data() {
    return {
      showOnlyStringsToTranslate: false,
      selectedLanguage: null
    };
  },
  computed: {
    baseFields() {
      return [
        { key: EN, label: "English", sortable: true },
        { key: RU, label: "Русский", sortable: true },
        { key: LT, label: "Lietuvių", sortable: true }
      ];
    },
    languages() {
      return [
        { value: null, text: this.$t("language.any") },
        { value: EN, text: "English" },
        { value: RU, text: "Русский" },
        { value: LT, text: "Lietuvių" }
      ];
    }
  },
  methods: {
    checkNeedToTranslateString(value, locale) {
      return value.endsWith(`_${locale}`) || value.endsWith(`(${locale})`);
    },
    updateString(id, locale, e) {
      if (e.target.value === "") {
        e.target.value = this.$store.getters["i18n/getStringValue"](id, locale);
      } else {
        this.$store.commit("i18n/updateString", { id, locale, value: e.target.value });
      }
    },
    filterItemsByNeedToTranslate(items) {
      if (this.showOnlyStringsToTranslate === false) return items;
      if (this.selectedLanguage === null) {
        return items.filter(
          x =>
            this.checkNeedToTranslateString(x[EN], EN) ||
            this.checkNeedToTranslateString(x[RU], RU) ||
            this.checkNeedToTranslateString(x[LT], LT)
        );
      } else {
        return items.filter(
          x =>
            (this.selectedLanguage === EN && this.checkNeedToTranslateString(x[EN], EN)) ||
            (this.selectedLanguage === RU && this.checkNeedToTranslateString(x[RU], RU)) ||
            (this.selectedLanguage === LT && this.checkNeedToTranslateString(x[LT], LT))
        );
      }
    }
  },
  beforeDestroy() {
    this.$store.commit("i18n/reset");
  }
};
