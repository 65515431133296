<template>
  <div v-if="issueCategory">
    <b-modal
      id="edit-issue-category-modal"
      @hidden="clear"
      @ok="updateIssueCategory"
      :title="$t('issue-category.edit-modal.title')"
      :ok-title="$t('issue-category.edit-modal.ok-btn-caption')"
      :cancel-title="$t('issue-category.cancel-btn-caption')"
    >
      <template v-slot:default>
        <b-form-group :label="$t('issue-category.title')">
          <b-form-input v-model="title"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('issue-category.short-title')">
          <b-form-input v-model="shortTitle"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('issue-category.checks')">
          <v-select
            multiple
            v-model="customCheckIds"
            :options="customChecks"
            :reduce="x => x.id"
            :getOptionLabel="x => `${x.title[$i18n.locale]}`"
          >
            <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
          </v-select>
        </b-form-group>
        <b-form-group :label="$t('issue-category.description')">
          <b-form-textarea v-model="description"></b-form-textarea>
        </b-form-group>
      </template>
    </b-modal>
  </div>
</template>

<script>
import converter from "@/services/converter.js";

export default {
  computed: {
    customChecks() {
      return this.$store.state.customChecks.customChecks;
    },
    issueCategory() {
      return this.$store.state.issueCategories.loadedIssueCategory;
    },
    title: {
      get() {
        return this.issueCategory.title[this.$i18n.locale];
      },
      set(value) {
        let title = converter.stringToStringTranslate(value, this.$i18n.locale);
        this.$store.commit("issueCategories/setLoadedIssueTitle", title);
      }
    },
    shortTitle: {
      get() {
        return this.issueCategory.shortTitle;
      },
      set(value) {
        this.$store.commit("issueCategories/setLoadedIssueShortTitle", value);
      }
    },
    customCheckIds: {
      get() {
        return this.issueCategory.customCheckIds;
      },
      set(value) {
        this.$store.commit("issueCategories/setLoadedIssueCustomCheckIds", value);
      }
    },
    description: {
      get() {
        return this.issueCategory.description;
      },
      set(value) {
        this.$store.commit("issueCategories/setLoadedIssueDescription", value);
      }
    }
  },
  methods: {
    clear() {
      this.$store.dispatch("issueCategories/clearLoadedIssueCategory");
    },
    async updateIssueCategory(e) {
      e.preventDefault();

      if (!this.title) {
        alert(this.$t("issue-category.error.empty-title"));
        return;
      }

      if (!this.shortTitle) {
        alert(this.$t("issue-category.error.empty-short-title"));
        return;
      }

      if (this.shortTitle.length > 10 || !new RegExp("^[A-Z]+$").test(this.shortTitle)) {
        alert(this.$t("issue-category.error.invalid-short-title-format"));
        return;
      }

      try {
        await this.$store.dispatch("issueCategories/updateLoadedIssueCategory");
      } catch (err) {
        if (err.data.errorCode) {
          alert(this.$t("issue-category.error." + err.data.errorCode));
        } else {
          alert(this.$t("issue-category.error.while-updating"));
        }
        return;
      }

      this.$bvModal.hide("edit-issue-category-modal");
    },
    show() {
      this.$bvModal.show("edit-issue-category-modal");
    }
  },
  mounted() {
    this.$store.dispatch("customChecks/loadData");
  }
};
</script>
