<template>
  <b-container fluid class="mb-3">
    <b-button @click="applyTableRowCountConfiguration">{{ $t("settings.system.appearance.apply-btn") }}</b-button>
    <div>
      {{ $t("settings.system.appearance.common-table-row-count-select") }}
      <v-select
        :clearable="false"
        :options="rowCountOptions"
        :reduce="x => x.value"
        :value="$store.getters['settings/appearance/commonTableRowCount']"
        @input="x => $store.commit('settings/appearance/setCommonTableRowCount', x)"
      >
        <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
      </v-select>
    </div>
    <div>
      {{ $t("settings.system.appearance.dashboard-table-row-count-select") }}
      <v-select
        :clearable="false"
        :options="rowCountOptions"
        :reduce="x => x.value"
        :value="$store.getters['settings/appearance/dashboardTableRowCount']"
        @input="x => $store.commit('settings/appearance/setDashboardTableRowCount', x)"
      >
        <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
      </v-select>
    </div>
  </b-container>
</template>

<script>
export default {
  computed: {
    rowCountOptions() {
      return [
        { value: 10, label: "10" },
        { value: 25, label: "25" },
        { value: 50, label: "50" },
        { value: 100, label: "100" },
        // no limit value - int max value
        { value: 2147483647, label: this.$t("settings.system.appearance.row-options.no-limit") }
      ];
    }
  },
  methods: {
    applyTableRowCountConfiguration() {
      this.$store.dispatch("settings/appearance/applyTableRowCountConfiguration");
    }
  },
  mounted() {
    this.$store
      .dispatch("settings/appearance/getInitState")
      .then(resp => this.$store.commit("settings/appearance/setTableRowCountConfiguration", resp.data))
      .catch(() => alert(this.$t("settings.system.appearance.error.general-getting-table-row-count-configuration")));
  }
};
</script>
