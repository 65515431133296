<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <add-custom-check></add-custom-check>
        <custom-checks-list></custom-checks-list>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AddCustomCheck from "./AddCustomCheck.vue";
import CustomChecksList from "./CustomChecksList.vue";

export default {
  components: {
    AddCustomCheck,
    CustomChecksList
  }
};
</script>
