<template>
  <b-card no-body>
    <b-tabs pills card vertical lazy>
      <b-tab :title="$t('settings.template.item-mng.title')" active>
        <product-list />
      </b-tab>
      <b-tab :title="$t('settings.template.product-group.title')">
        <goods-group-list />
      </b-tab>
      <b-tab :title="$t('settings.template.picking-template.title')">
        <b-container fluid><template-list /></b-container>
      </b-tab>
      <b-tab :title="$t('settings.template.custom-checks.title')">
        <custom-checks></custom-checks>
      </b-tab>
      <b-tab :title="$t('settings.template.issues.title')">
        <issue-category-list></issue-category-list>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import ProductList from "@/components/ProductList.vue";
import GoodsGroupList from "@/components/GoodsGroupList.vue";
import TemplateList from "@/components/template/TemplateList.vue";
import CustomChecks from "./templates/CustomChecks.vue";
import IssueCategoryList from "./templates/issues/IssueCategoryList";

export default {
  components: {
    "product-list": ProductList,
    "goods-group-list": GoodsGroupList,
    "template-list": TemplateList,
    CustomChecks,
    IssueCategoryList
  }
};
</script>
