<template>
  <b-tabs content-class="mt-3" fill lazy class="settings-tabs">
    <b-tab :title="$t('settings.user-management.title')" active>
      <user-managment-settings />
    </b-tab>
    <b-tab :title="$t('settings.system.title')">
      <system-settings />
    </b-tab>
    <b-tab :title="$t('settings.transport.title')">
      <transport-settings />
    </b-tab>
    <b-tab :title="$t('settings.template.title')">
      <template-settings />
    </b-tab>
  </b-tabs>
</template>

<script>
import SystemSettings from "./system/SystemSettings.vue";
import TemplateSettings from "./TemplateSettings.vue";
import TransportSettings from "./TransportSettings.vue";
import UserManagmentSettings from "./UserManagementSettings.vue";

export default {
  components: {
    "system-settings": SystemSettings,
    "template-settings": TemplateSettings,
    "transport-settings": TransportSettings,
    "user-managment-settings": UserManagmentSettings
  }
};
</script>
