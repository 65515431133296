<template>
  <b-container fluid>
    <div class="my-2">
      <b-button @click="submit">{{ $t("settings.system.database.save-change-btn") }}</b-button>
    </div>
    <b-form-group :label="$t('settings.system.database.server')">
      <b-form-input
        :value="$store.getters['settings/db/server']"
        @change="x => $store.commit('settings/db/setServer', x)"
      />
    </b-form-group>
    <b-form-group :label="$t('settings.system.database.database')">
      <b-form-input
        :value="$store.getters['settings/db/database']"
        @change="x => $store.commit('settings/db/setDatabase', x)"
      />
    </b-form-group>
    <b-form-group :label="$t('settings.system.database.user')">
      <b-form-input
        :value="$store.getters['settings/db/user']"
        @change="x => $store.commit('settings/db/setUser', x)"
      />
    </b-form-group>
    <b-form-group :label="$t('settings.system.database.password')">
      <b-form-input
        type="password"
        :value="$store.getters['settings/db/password']"
        @change="x => $store.commit('settings/db/setPassword', x)"
      />
    </b-form-group>
  </b-container>
</template>

<script>
export default {
  methods: {
    submit() {
      this.$store
        .dispatch("settings/db/applyConnectionString")
        .catch(() => alert(this.$t("settings.system.database.error-setting-data")));
    }
  },
  mounted() {
    this.$store
      .dispatch("settings/db/getInitState")
      .then(resp => this.$store.commit("settings/db/setConnectionString", resp.data))
      .catch(() => alert(this.$t("settings.system.database.error-getting-data")));
  }
};
</script>
