<template>
  <div>
    <b-button @click="onShowAddVehilceModalClick" class="mb-2">{{ $t("custom-checks.add-btn-caption") }}</b-button>
    <b-modal
      id="add-custom-check-modal"
      @ok="addCustomCheck"
      size="lg"
      :title="$t('custom-checks.add-check-modal.title')"
      :ok-title="$t('custom-checks.add-check-modal.ok-btn-caption')"
      :cancel-title="$t('custom-checks.add-check-modal.cancel-btn-caption')"
      :ok-disabled="!isValid"
      scrollable
    >
      <template>
        <b-form-group :label="$t('custom-checks.add-check-modal.title-label')">
          <b-form-input v-model.trim="title" :state="isTitleValid"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('custom-checks.add-check-modal.type-label')">
          <v-select v-model="category" :options="issueCategories" :getOptionLabel="x => x.title[$i18n.locale]">
            <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
          </v-select>
        </b-form-group>
        <b-form-group :label="$t('custom-checks.add-check-modal.description-label')">
          <b-form-textarea v-model.trim="description" :state="isDescriptionValid"></b-form-textarea>
        </b-form-group>
        <b-form-group>
          <check-items v-model="checkItems"></check-items>
        </b-form-group>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/services/api.js";
import CheckItems from "./CheckItems.vue";
import converter from "@/services/converter.js";

export default {
  components: {
    CheckItems
  },
  data() {
    return this.getDefaultData();
  },
  computed: {
    issueCategories() {
      return this.$store.state.issueCategories.items;
    },
    isTitleValid() {
      return this.title !== "";
    },
    isDescriptionValid() {
      return this.description !== "";
    },
    isCategorySelected() {
      return this.category != null;
    },
    isValid() {
      return this.isTitleValid && this.isDescriptionValid;
    },
    lastValidationError() {
      if (!this.isCategorySelected) {
        return this.$t("custom-checks.error.no-issue-category-selected");
      }
      if (this.checkItems.length == 0) {
        return this.$t("custom-checks.error.no-check-items");
      }
      if (this.checkItems.find(x => x.title[this.$i18n.locale] === "")) {
        return this.$t("custom-checks.error.empty-check-item");
      }
      let uniqueTitles = new Set(this.checkItems.map(x => x.title[this.$i18n.locale]));
      if (uniqueTitles.size !== this.checkItems.length) {
        return this.$t("custom-checks.error.duplicate-check-item");
      }
      return null;
    }
  },
  methods: {
    getDefaultData() {
      return {
        title: "",
        category: null,
        description: "",
        checkItems: []
      };
    },
    reset() {
      Object.assign(this.$data, this.getDefaultData());
    },
    addCustomCheck(evt) {
      evt.preventDefault();
      let validationError = this.lastValidationError;
      if (validationError !== null) {
        alert(validationError);
        return;
      }

      let vm = {
        title: converter.stringToStringTranslate(this.title, this.$i18n.locale),
        issueCategoryId: this.category.id,
        description: converter.stringToStringTranslate(this.description, this.$i18n.locale),
        checkItems: this.checkItems.map((x, i) => {
          return {
            title: converter.stringToStringTranslate(x.title[this.$i18n.locale], this.$i18n.locale),
            order: i
          };
        })
      };

      api
        .addCustomCheck(vm)
        .then(() => {
          this.$store
            .dispatch("customChecks/loadData")
            .catch(() => alert(this.$t("custom-checks.error.while-loading-list")));
          this.$bvModal.hide("add-custom-check-modal");
        })
        .catch(() => alert(this.$t("custom-checks.error.while-adding")));
    },
    onShowAddVehilceModalClick() {
      this.reset();
      this.$bvModal.show("add-custom-check-modal");
    }
  },
  mounted() {
    this.$store.dispatch("issueCategories/load");
  }
};
</script>
