<template>
  <b-container fluid>
    <b-input-group class="mb-2">
      <b-input v-model="typeToAdd" :placeholder="$t('transport-settings.types.placeholder-type')"></b-input>
      <b-button @click="addType">{{ $t("transport-settings.types.add-type") }}</b-button>
    </b-input-group>
    <b-table :items="typesValues" :fields="fields" responsive>
      <template v-slot:cell(title)="row">{{ row.value[$i18n.locale] }}</template>
      <template v-slot:cell(edit)="row">
        <b-button @click="editTypesModal(row.item)">{{ $t("transport-settings.types.edit") }}</b-button>
      </template>
    </b-table>
    <b-modal
      id="editTypesItemModal"
      :title="$t('transport-settings.types.editing')"
      @ok="editItem"
      :ok-disabled="this.editedItem == '' || this.editModalItem.title[$i18n.locale] == this.editedItem"
      :ok-title="$t('transport-settings.modal.edit-btn')"
      :cancel-title="$t('transport-settings.modal.cancel-btn')"
    >
      <b-input v-model="editedItem" class="mb-2"></b-input>
      <b-button variant="danger" @click="$bvModal.show('deleteTypesItemModal')">
        {{ $t("transport-settings.modal.delete-btn") }}
      </b-button>
    </b-modal>
    <b-modal
      id="deleteTypesItemModal"
      :title="editModalItem.title[$i18n.locale]"
      @ok="deleteItem"
      :ok-disabled="editedItem !== deletedItem"
      @hidden="resetDeleteModal"
      :ok-title="$t('transport-settings.modal.delete-btn')"
      :cancel-title="$t('transport-settings.modal.cancel-btn')"
    >
      <h3>{{ $t("transport-settings.types.enter-type-for-delete") }}</h3>
      <b-input v-model="deletedItem"></b-input>
    </b-modal>
  </b-container>
</template>

<script>
import { EN, RU, LT } from "@/const/langs.js";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      typesValues: [],
      fields: [
        { key: "title", label: this.$t("transport-settings.types.types") },
        { key: "edit", label: this.$t("transport-settings.types.editing"), thStyle: "width: 12rem" }
      ],
      typeToAdd: "",
      deletedItem: null,
      editedItem: "",
      editModalItem: {
        id: "",
        title: {
          en: "",
          ru: "",
          lt: ""
        }
      }
    };
  },
  methods: {
    ...mapActions(["vehicleType/getAll", "vehicleType/add", "vehicleType/update", "vehicleType/remove"]),
    addType() {
      if (this.typeToAdd == "") {
        alert(this.$t("transport-settings.types.empty-type"));
      } else {
        let vm = {
          id: 0,
          title: {
            [EN]: this.typeToAdd + `_${EN}`,
            [RU]: this.typeToAdd + `_${RU}`,
            [LT]: this.typeToAdd + `_${LT}`,
            id: 0
          }
        };
        vm.title[this.$i18n.locale] = this.typeToAdd;
        this["vehicleType/add"](vm)
          .then(() => {
            this.updateTable();
            this.typeToAdd = "";
          })
          .catch(() => alert(this.$t("transport-settings.errors.error-adding")));
      }
    },
    editTypesModal(value) {
      this.editedItem = value.title[this.$i18n.locale];
      this.editModalItem = value;
      this.$bvModal.show("editTypesItemModal");
    },
    editItem() {
      this.editModalItem.title[this.$i18n.locale] = this.editedItem;
      this["vehicleType/update"](this.editModalItem)
        .then(() => {
          this.updateTable();
          this.$bvModal.hide("editTypesItemModal");
        })
        .catch(() => alert(this.$t("transport-settings.errors.error-while-editing")));
    },
    deleteItem() {
      let elementToDelete = this.typesValues.find(x => x.title.id === this.editModalItem.title.id);
      this["vehicleType/remove"](elementToDelete)
        .then(() => {
          this.updateTable();
          this.$bvModal.hide("deleteTypesItemModal");
          this.$bvModal.hide("editTypesItemModal");
        })
        .catch(() => alert(this.$t("transport-settings.errors.error-deleting")));
    },
    resetDeleteModal() {
      this.deletedItem = null;
    },
    updateTable() {
      this["vehicleType/getAll"]().then(resp => (this.typesValues = resp.data));
    }
  },
  mounted() {
    this.updateTable();
  }
};
</script>
