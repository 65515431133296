<template>
  <b-container fluid>
    <b-row class="mb-2">
      <b-col>
        <add-issue-category></add-issue-category>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table :fields="fields" :items="itemsFiltered" responsive>
          <template v-slot:top-row>
            <td>
              <b-input v-model.trim="filter.title" :placeholder="$t('issue-category.title')"></b-input>
            </td>
            <td>
              <b-input v-model.trim="filter.shortTitle" :placeholder="$t('issue-category.short-title')"></b-input>
            </td>
            <td>
              <b-input v-model.trim="filter.description" :placeholder="$t('issue-category.description')"></b-input>
            </td>
            <td>
              <b-input v-model.trim="filter.count" :placeholder="$t('issue-category.count')"></b-input>
            </td>
          </template>
          <template v-slot:cell(title)="row">
            <b-link @click="openEditControl(row.item.id)">{{ row.value[$i18n.locale] }}</b-link>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <edit-issue-category ref="editControl"></edit-issue-category>
  </b-container>
</template>

<script>
import AddIssueCategory from "./AddIssueCategory";
import EditIssueCategory from "./EditIssueCategory";

import comparator from "@/services/string-comparator.js";

export default {
  components: {
    AddIssueCategory,
    EditIssueCategory
  },
  data() {
    return {
      filter: {
        title: "",
        shortTitle: "",
        count: "",
        description: ""
      }
    };
  },
  computed: {
    items() {
      return this.$store.state.issueCategories.items;
    },
    fields() {
      return [
        { key: "title", label: this.$t("issue-category.title"), sortable: true },
        { key: "shortTitle", label: this.$t("issue-category.short-title"), sortable: true },
        { key: "description", label: this.$t("issue-category.description") },
        { key: "count", label: this.$t("issue-category.count"), sortable: true }
      ];
    },
    itemsFiltered() {
      return this.items.filter(
        x =>
          comparator.compareRows(x.title[this.$i18n.locale], this.filter.title) &&
          comparator.compareRows(x.shortTitle, this.filter.shortTitle) &&
          x.count.toString().includes(this.filter.count) &&
          comparator.compareRows(x.description || "", this.filter.description)
      );
    }
  },
  methods: {
    async openEditControl(id) {
      await this.$store.dispatch("issueCategories/loadIssueCategory", id);
      this.$refs.editControl.show();
    }
  },
  mounted() {
    this.$store.dispatch("issueCategories/load");
  }
};
</script>
