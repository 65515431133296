<template>
  <div>
    <b-table :fields="fields" :items="filteredItems" responsive>
      <template v-slot:top-row>
        <td>
          <b-input v-model.trim="filter.title" :placeholder="$t('custom-checks.filter.title')"></b-input>
        </td>
        <td>
          <b-input v-model.trim="filter.description" :placeholder="$t('custom-checks.filter.description')"></b-input>
        </td>
        <td></td>
      </template>
      <template v-slot:cell(title)="row">
        <b-link @click="onCustomCheckClick(row.item.id)">{{ row.value[$i18n.locale] }}</b-link>
      </template>
      <template v-slot:cell(description)="row">
        {{ row.value[$i18n.locale] }}
      </template>
    </b-table>
    <edit-custom-check ref="editCheckControl"></edit-custom-check>
  </div>
</template>

<script>
import comparator from "@/services/string-comparator.js";
import EditCustomCheck from "./EditCustomCheck.vue";

export default {
  components: {
    EditCustomCheck
  },
  data() {
    return {
      filter: {
        title: "",
        description: ""
      }
    };
  },
  computed: {
    fields() {
      return [
        { key: "title", label: this.$t("custom-checks.table.title"), sortable: true },
        { key: "description", label: this.$t("custom-checks.table.description"), sortable: true },
        { key: "checkItemsCount", label: this.$t("custom-checks.table.check-items-count"), sortable: true }
      ];
    },
    filteredItems() {
      let allItems = this.$store.state.customChecks.customChecks;
      return allItems.filter(
        x =>
          comparator.compareRows(x.title[this.$i18n.locale], this.filter.title) &&
          comparator.compareRows(x.description[this.$i18n.locale], this.filter.description)
      );
    }
  },
  methods: {
    onCustomCheckClick(id) {
      this.$refs.editCheckControl.showEditControl(id);
    }
  },
  mounted() {
    this.$store.dispatch("customChecks/loadData").catch(() => alert(this.$t("custom-checks.error.while-loading-list")));
  }
};
</script>
