<template>
  <div v-if="editCustomCheckModel">
    <b-modal
      id="edit-custom-check-modal"
      :title="oldCheckTitle"
      size="lg"
      @ok="onUpdateCustomCheckClick"
      :ok-title="$t('custom-checks.edit-check-modal.ok-btn-caption')"
      :cancel-title="$t('custom-checks.edit-check-modal.cancel-btn-caption')"
      :ok-disabled="!isValid"
    >
      <template>
        <b-form-group :label="$t('custom-checks.edit-check-modal.title-label')">
          <b-form-input v-model.trim="editCustomCheckModel.title[$i18n.locale]" :state="isTitleValid"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('custom-checks.edit-check-modal.type-label')">
          <v-select
            v-model="editCustomCheckModel.issueCategoryId"
            :reduce="x => x.id"
            :options="issueCategories"
            :getOptionLabel="x => x.title[$i18n.locale]"
          >
            <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
          </v-select>
        </b-form-group>
        <b-form-group :label="$t('custom-checks.edit-check-modal.description-label')">
          <b-form-textarea
            v-model.trim="editCustomCheckModel.description[$i18n.locale]"
            :state="isDescriptionValid"
          ></b-form-textarea>
        </b-form-group>
        <b-form-group>
          <check-items v-model="editCustomCheckModel.checkItems"></check-items>
        </b-form-group>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/services/api.js";
import CheckItems from "./CheckItems.vue";
import converter from "@/services/converter.js";

export default {
  components: {
    CheckItems
  },
  data() {
    return this.getDefaultData();
  },
  computed: {
    issueCategories() {
      return this.$store.state.issueCategories.items;
    },
    isTitleValid() {
      return this.editCustomCheckModel.title[this.$i18n.locale] !== "";
    },
    isDescriptionValid() {
      return this.editCustomCheckModel.description[this.$i18n.locale] !== "";
    },
    isValid() {
      return this.isTitleValid && this.isDescriptionValid;
    },
    lastValidationError() {
      if (this.editCustomCheckModel.checkItems.length == 0) {
        return this.$t("custom-checks.error.no-check-items");
      }
      if (this.editCustomCheckModel.checkItems.find(x => x.title[this.$i18n.locale] === "")) {
        return this.$t("custom-checks.error.empty-check-item");
      }
      let uniqueTitles = new Set(this.editCustomCheckModel.checkItems.map(x => x.title[this.$i18n.locale]));
      if (uniqueTitles.size !== this.editCustomCheckModel.checkItems.length) {
        return this.$t("custom-checks.error.duplicate-check-item");
      }
      return null;
    }
  },
  methods: {
    getDefaultData() {
      return {
        editCustomCheckModel: null,
        oldCheckTitle: ""
      };
    },
    reset() {
      Object.assign(this.$data, this.getDefaultData());
    },
    showEditControl(customCheckId) {
      this.loadData(customCheckId)
        .then(() => this.$bvModal.show("edit-custom-check-modal"))
        .catch(() => alert(this.$t("custom-checks.error.while-loading-item-for-edit")));
    },
    loadData(customCheckId) {
      return api.getCustomCheck(customCheckId).then(resp => {
        this.editCustomCheckModel = resp.data;
        this.oldCheckTitle = this.editCustomCheckModel.title[this.$i18n.locale];
      });
    },
    onUpdateCustomCheckClick(evt) {
      evt.preventDefault();
      let validationError = this.lastValidationError;
      if (validationError !== null) {
        alert(validationError);
        return;
      }

      // set string translates for new items, set order
      this.editCustomCheckModel.checkItems = this.editCustomCheckModel.checkItems.map((x, i) => {
        x.order = i;
        if (!x.id) {
          // new item
          x.title = converter.stringToStringTranslate(x.title[this.$i18n.locale], this.$i18n.locale);
        }
        return x;
      });

      api
        .updateCustomCheck(this.editCustomCheckModel)
        .then(() => {
          this.$store
            .dispatch("customChecks/loadData")
            .catch(() => alert(this.$t("custom-checks.error.while-loading-list")));
          this.$bvModal.hide("edit-custom-check-modal");
        })
        .catch(() => alert(this.$t("custom-checks.error.while-updating")));
    }
  },
  mounted() {
    this.$store.dispatch("issueCategories/load");
  }
};
</script>
