<template>
  <b-card no-body>
    <b-tabs pills card vertical fill>
      <b-tab :title="$t('transport-settings.categories.category-setting')"><car-categories /></b-tab>
      <b-tab :title="$t('transport-settings.types.type-setting')"><car-types /></b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import CarTypes from "./transport/CarTypes.vue";
import CarCategories from "./transport/CarCategories.vue";
export default {
  components: {
    "car-types": CarTypes,
    "car-categories": CarCategories
  }
};
</script>
