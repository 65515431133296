<template>
  <b-modal
    id="edit-user-modal"
    :title="loc('title.edit')"
    :ok-title="loc('ok.edit')"
    :cancel-title="loc('cancel')"
    @hidden="reset"
    @ok="onSubmit"
  >
    <template v-slot:default>
      <b-form-group :label="loc('username')">
        <b-form-input v-model="user.userName" />
      </b-form-group>
      <b-form-group :label="loc('login')">
        <b-form-input v-model="user.login" />
      </b-form-group>
      <b-form-group :label="loc('role')">
        <v-select :clearable="false" :options="roles" label="label" :reduce="label => label.key" v-model="user.role" />
      </b-form-group>
      <b-form-group :label="loc('email')">
        <b-form-input v-model="user.email" />
      </b-form-group>
      <b-form-group :label="loc('phone')">
        <b-form-input v-model="user.phone" />
      </b-form-group>
      <b-row class="mb-2">
        <b-col>
          <b-button variant="danger" class="mr-3" @click="onRemoveUser">{{ loc("removeUser") }}</b-button>
          <b-modal
            id="remove-user-modal"
            :title="loc('remove-user-modal.title')"
            :ok-title="loc('remove-user-modal.ok')"
            :cancel-title="loc('remove-user-modal.cancel')"
            ok-variant="danger"
            :ok-disabled="confirmationLogin !== user.login"
            @ok="removeUser"
          >
            <template v-slot:default>
              <b-form-group :label="loc('remove-user-modal.user')">
                <b-form-input v-model="confirmationLogin"></b-form-input>
              </b-form-group>
            </template>
          </b-modal>
          <b-button variant="danger" class="mr-3" @click="resetPassword">{{ loc("reset-password") }}</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import { ADMINISTRATOR, MANAGER, DRIVER } from "@/const/user-role.js";
import { UPDATE_USERS } from "@/const/events.js";

export default {
  data() {
    return {
      confirmationLogin: ""
    };
  },
  computed: {
    roles() {
      return [
        { key: ADMINISTRATOR, label: this.$t("roles.administrator") },
        { key: MANAGER, label: this.$t("roles.manager") },
        { key: DRIVER, label: this.$t("roles.driver") },
        { key: null, label: this.$t("roles.no-role") }
      ];
    }
  },
  props: {
    user: null
  },
  methods: {
    ...mapActions(["users/update", "users/remove", "users/reset-password"]),
    reset() {
      this.confirmationLogin = "";
    },
    onRemoveUser(e) {
      e.preventDefault();
      this.$bvModal.show("remove-user-modal");
    },
    resetPassword() {
      this["users/reset-password"](this.user.id).catch(error => {
        if (error.status == 404) alert(this.loc("server-errors.user-not-found"));
        else alert(this.loc("server-errors.generic-server-error"));
      });
    },
    removeUser() {
      this["users/remove"](this.user.id)
        .then(() => {
          this.$bus.emit(UPDATE_USERS);
          this.$bvModal.hide("edit-user-modal");
        })
        .catch(error => {
          if (error.status == 404) alert(this.loc("server-errors.user-not-found"));
          else alert(this.loc("server-errors.generic-server-error"));
        });
    },
    updateUser() {
      this["users/update"](this.user)
        .then(() => {
          this.$bus.emit(UPDATE_USERS);
          this.$bvModal.hide("edit-user-modal");
        })
        .catch(error => {
          if (!error.data) {
            alert(this.loc("server-errors.generic-server-error"));
            return;
          }

          if (error.data.invalidRole) alert(this.loc("server-errors.invalid-role"));
          else if (error.data.invalidUsername) alert(this.loc("server-errors.invalid-username"));
          else if (error.data.invalidLogin) alert(this.loc("server-errors.invalid-login"));
          else alert(this.loc("server-errors.unexpected-error"));
        });
    },
    localCheck() {
      if (this.user.userName == "") return { ok: false, message: "emptyUsername" };

      if (!/^[A-Za-z._]+$/.test(this.user.login)) return { ok: false, message: "invalidLogin" };

      if (!/^((\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+))$/.test(this.user.email))
        return { ok: false, message: "invalidEmail" };
      if (this.user.phone == "") return { ok: false, message: "emptyPhoneNumber" };

      return { ok: true, message: null };
    },
    onSubmit(e) {
      e.preventDefault();

      var checkResult = this.localCheck();
      if (!checkResult.ok) {
        if (checkResult.message != null) alert(this.loc("checks." + checkResult.message));

        return;
      }

      this.updateUser();
    },
    loc(tag) {
      return this.$t("settings.user-management.user.user-modal." + tag);
    }
  }
};
</script>
