<template>
  <b-card no-body>
    <b-tabs pills card vertical lazy>
      <b-tab :title="$t('settings.user-management.user.title')">
        <users />
      </b-tab>
      <b-tab :title="$t('settings.user-management.role.title')">
        <b-card-text>{{ $t("settings.user-management.role.title") }}</b-card-text>
      </b-tab>
      <b-tab :title="$t('settings.user-management.group.title')">
        <b-card-text>{{ $t("settings.user-management.group.title") }}</b-card-text>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import Users from "./users/Users.vue";

export default {
  components: {
    users: Users
  }
};
</script>
