<template>
  <div>
    <b-button @click="$refs.fileInput.click()">
      {{ $t("settings.user-management.user.import") }}
    </b-button>
    <input
      type="file"
      ref="fileInput"
      class="d-none"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      @change="onImportUser"
    />
    <b-modal
      id="import-user-result"
      :ok-title="okTitle"
      :title="$t('import-user.modal.title')"
      @ok="onOk"
      @hidden="reset"
      ok-only
    >
      <template v-slot:default>
        <div>{{ $t("import-user.success", { count: importResult.importedCount }) }}</div>
        <div v-if="hasUsersWithoutRole">
          <h6>{{ $t("import-user.users-without-role", { count: importResult.usersWithoutRole.length }) }}</h6>
          <v-select v-model="usersRole" :options="roles" :reduce="x => x.value">
            <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
          </v-select>
        </div>
        <div v-if="hasErrors">
          <h6>{{ $t("import-vehicle.modal.error-list") }}</h6>
          <ul>
            <li v-for="(error, i) in importErrors" :key="i">{{ error }}</li>
          </ul>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ADMINISTRATOR, MANAGER, DRIVER } from "@/const/user-role.js";
import { UPDATE_USERS } from "@/const/events.js";

import { mapActions } from "vuex";

export default {
  data() {
    return this.getDefaultData();
  },
  computed: {
    hasErrors() {
      return this.importResult.errors && this.importResult.errors.length > 0;
    },
    importErrors() {
      if (!this.hasErrors) return [];
      return this.importResult.errors.map(x => this.$t(`import-user.error.${x.errorCode}`, { row: x.row }));
    },
    hasUsersWithoutRole() {
      return this.importResult.usersWithoutRole && this.importResult.usersWithoutRole.length > 0;
    },
    roles() {
      return [
        { value: ADMINISTRATOR, label: this.$t("roles.administrator") },
        { value: MANAGER, label: this.$t("roles.manager") },
        { value: DRIVER, label: this.$t("roles.driver") }
      ];
    },
    okTitle() {
      return this.hasUsersWithoutRole === true
        ? this.$t("import-user.modal.import-users-without-role")
        : this.$t("import-user.modal.ok-title");
    }
  },
  methods: {
    ...mapActions(["users/import", "users/add"]),
    getDefaultData() {
      return {
        usersRole: null,
        importResult: {
          errors: [],
          importedCount: 0,
          usersWithoutRole: []
        }
      };
    },
    reset() {
      Object.assign(this.$data, this.getDefaultData());
    },
    onImportUser(e) {
      let vm = new FormData();
      vm.append("file", e.target.files[0]);
      this["users/import"](vm)
        .then(resp => {
          this.importResult = resp.data;
          this.$bvModal.show("import-user-result");
          if (this.importResult.importedCount > 0) {
            this.$bus.emit(UPDATE_USERS);
          }
        })
        .catch(err => {
          if (err.data.errorCode) {
            alert(this.$t(`import-user.error.${err.data.errorCode}`));
          } else {
            alert(this.$t("import-user.error.general-error"));
          }
        })
        .finally(() => (e.target.value = ""));
    },
    onOk(e) {
      e.preventDefault();
      if (this.hasUsersWithoutRole) {
        if (!this.usersRole) {
          alert(this.$t("import-user.error.select-role-for-users"));
          return;
        }

        Promise.all(this.importResult.usersWithoutRole.map(x => this["users/add"]({ ...x, role: this.usersRole })))
          .then(() => this.$nextTick(() => this.$bvModal.hide("import-user-result")))
          .catch(() => alert("import-user.error.error-adding-users"))
          .finally(() => this.$bus.emit(UPDATE_USERS));
      } else {
        this.$nextTick(() => this.$bvModal.hide("import-user-result"));
      }
    }
  }
};
</script>
