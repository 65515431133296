<template>
  <b-card no-body>
    <b-tabs pills card vertical lazy>
      <b-tab :title="$t('settings.system.user-language.title')">
        <user-language-settings />
      </b-tab>
      <b-tab :title="$t('settings.system.ui-language.title')">
        <ui-language-settings />
      </b-tab>
      <b-tab :title="$t('settings.system.mail.title')" />
      <b-tab :title="$t('settings.system.notification.title')" />
      <b-tab :title="$t('settings.system.database.title')" v-if="isAdmin()">
        <db-settings />
      </b-tab>
      <b-tab :title="$t('settings.system.appearance.title')">
        <appearance-settings />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import AppearanceSettings from "./AppearanceSettings.vue";
import UILanguageSettings from "./i18n/UILanguageSettings.vue";
import UserLanguageSettings from "./i18n/UserLanguageSettings.vue";
import DbSettings from "./DbSettings.vue";

import auth from "@/services/auth.js";
import { ADMINISTRATOR } from "@/const/user-role.js";

export default {
  components: {
    "appearance-settings": AppearanceSettings,
    "ui-language-settings": UILanguageSettings,
    "user-language-settings": UserLanguageSettings,
    "db-settings": DbSettings
  },
  methods: {
    isAdmin() {
      return auth.userRole() == ADMINISTRATOR;
    }
  }
};
</script>
