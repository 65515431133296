<template>
  <b-container fluid>
    <div class="d-flex">
      <b-button v-b-modal.new-user-modal class="mb-2 mr-2">{{ $t("settings.user-management.user.add") }}</b-button>
      <import-user />
    </div>
    <b-table :fields="fields" :items="filteredItems" responsive>
      <template v-slot:top-row>
        <td><b-input v-model.trim="filter.userName"></b-input></td>
        <td><b-input v-model.trim="filter.login"></b-input></td>
        <td><b-input v-model.trim="filter.role"></b-input></td>
        <td><b-input v-model.trim="filter.email"></b-input></td>
        <td><b-input v-model.trim="filter.phone"></b-input></td>
        <td />
      </template>
      <template v-slot:cell(role)="row">{{ formatRole(row.value) }}</template>
      <template v-slot:cell(phone)="row">
        <phone-number :phoneNumber="row.value" />
      </template>
      <template v-slot:cell(actions)="row">
        <b-button v-b-modal.edit-user-modal @click="selectUser(row.item)">
          {{ $t("settings.user-management.user.edit") }}
        </b-button>
      </template>
    </b-table>
    <new-user-modal />
    <edit-user-modal v-bind:user="selectedUser" />
  </b-container>
</template>

<script>
import NewUserModal from "./NewUserModal.vue";
import EditUserModal from "./EditUserModal.vue";
import ImportUser from "./ImportUser.vue";
import PhoneNumber from "@/components/PhoneNumber.vue";

import comparator from "@/services/string-comparator.js";

import { UPDATE_USERS } from "@/const/events.js";
import { ADMINISTRATOR, MANAGER, DRIVER } from "@/const/user-role.js";

import { mapActions } from "vuex";

export default {
  data() {
    return {
      items: [],
      selectedUser: null,
      filter: {
        userName: "",
        login: "",
        role: "",
        email: "",
        phone: ""
      }
    };
  },
  components: {
    "new-user-modal": NewUserModal,
    "edit-user-modal": EditUserModal,
    "import-user": ImportUser,
    "phone-number": PhoneNumber
  },
  computed: {
    fields() {
      return [
        { key: "userName", label: this.$t("settings.user-management.user.username"), sortable: true },
        { key: "login", label: this.$t("settings.user-management.user.login"), sortable: true },
        { key: "role", label: this.$t("settings.user-management.user.role"), sortable: true },
        { key: "email", label: this.$t("settings.user-management.user.email"), sortable: true },
        { key: "phone", label: this.$t("settings.user-management.user.phone"), sortable: true },
        { key: "actions", label: "", sortable: false }
      ];
    },
    filteredItems() {
      return this.items.filter(
        x =>
          comparator.compareRows(x.userName, this.filter.userName) &&
          comparator.compareRows(this.formatRole(x.role), this.filter.role) &&
          comparator.compareRows(x.login, this.filter.login) &&
          comparator.compareRows(x.email, this.filter.email) &&
          comparator.compareRows(x.phone, this.filter.phone)
      );
    }
  },
  methods: {
    ...mapActions(["users/getAll"]),
    getUsers() {
      this["users/getAll"]().then(resp => (this.items = resp.data));
    },
    formatRole(role) {
      if (role == null || role == "") return this.$t("roles.no-role");

      switch (role) {
        case ADMINISTRATOR:
          return this.$t("roles.administrator");
        case MANAGER:
          return this.$t("roles.manager");
        case DRIVER:
          return this.$t("roles.driver");
      }

      return role;
    },
    selectUser(row) {
      this.selectedUser = { ...row };
    }
  },
  mounted() {
    this.$bus.on(UPDATE_USERS, this.getUsers);
    this.getUsers();
  },
  beforeDestroy() {
    this.$bus.off(UPDATE_USERS, this.getUsers);
  }
};
</script>
