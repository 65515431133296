<template>
  <div>
    <b-form-datalist id="input-list" :options="localizedOptions"></b-form-datalist>
    <b-table :items="value" :fields="fields">
      <template v-slot:cell(title)="row">
        <b-input
          :value="row.item.title[$i18n.locale]"
          @input="onCheckItemChanged($event, row.index)"
          :state="!isEmpty(row.item.title[$i18n.locale])"
          list="input-list"
        ></b-input>
      </template>
      <template v-slot:cell(actions)="row">
        <b-button @click="onDeleteCheckItemClick(row.index)">
          {{ $t("custom-checks.check-items.remove-btn-caption") }}
        </b-button>
      </template>
    </b-table>
    <b-button @click="onAddEmptyCheckItemClick">{{ $t("custom-checks.check-items.add-btn-caption") }}</b-button>
  </div>
</template>

<script>
import { RU, EN, LT } from "@/const/langs.js";
import api from "@/services/api.js";

export default {
  data() {
    return {
      localizedOptions: []
    };
  },
  props: {
    value: {
      // array of objects {id, stringTranslate, order}
      type: Array,
      required: true
    }
  },
  computed: {
    fields() {
      return [
        { key: "title", label: this.$t("custom-checks.check-items.title") },
        { key: "actions", label: "" }
      ];
    }
  },
  methods: {
    isEmpty(value) {
      return value === "";
    },
    onCheckItemChanged(evt, i) {
      let newValue = [...this.value];
      newValue[i].title[this.$i18n.locale] = evt;
      this.$emit("input", newValue);
    },
    onDeleteCheckItemClick(i) {
      let newValue = [...this.value];
      newValue.splice(i, 1);
      this.$emit("input", newValue);
    },
    onAddEmptyCheckItemClick() {
      let newValue = [...this.value];
      newValue.push({
        title: {
          [RU]: "",
          [EN]: "",
          [LT]: ""
        }
      });
      this.$emit("input", newValue);
    }
  },
  mounted() {
    api.getCustomChecksItemsTitles(this.$i18n.locale).then(resp => (this.localizedOptions = resp.data));
  }
};
</script>
