<template>
  <b-modal
    id="new-user-modal"
    :title="loc('title.new')"
    :ok-title="loc('ok.new')"
    :cancel-title="loc('cancel')"
    @hidden="reset"
    @ok="onSubmit"
  >
    <template v-slot:default>
      <b-form-group :label="loc('username')">
        <b-form-input v-model="user.userName" />
      </b-form-group>
      <b-form-group :label="loc('login')">
        <b-form-input v-model="user.login" />
      </b-form-group>
      <b-form-group :label="loc('role')">
        <v-select :clearable="false" :options="roles" label="label" :reduce="label => label.key" v-model="user.role" />
      </b-form-group>
      <b-form-group :label="loc('email')">
        <b-form-input v-model="user.email" />
      </b-form-group>
      <b-form-group :label="loc('phone')">
        <b-form-input v-model="user.phone" />
      </b-form-group>
    </template>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import { ADMINISTRATOR, MANAGER, DRIVER } from "@/const/user-role.js";
import { UPDATE_USERS } from "@/const/events.js";

export default {
  data() {
    return {
      user: {
        userName: "",
        login: "",
        email: "",
        phone: "",
        role: null
      }
    };
  },
  computed: {
    roles() {
      return [
        { key: ADMINISTRATOR, label: this.$t("roles.administrator") },
        { key: MANAGER, label: this.$t("roles.manager") },
        { key: DRIVER, label: this.$t("roles.driver") },
        { key: null, label: this.$t("roles.no-role") }
      ];
    }
  },
  methods: {
    ...mapActions(["users/add"]),
    reset() {
      this.user.userName = "";
      this.user.login = "";
      this.user.email = "";
      this.user.phone = "";
      this.user.role = null;
    },
    createUser() {
      this["users/add"](this.user)
        .then(() => {
          this.$bus.emit(UPDATE_USERS);
          this.$bvModal.hide("new-user-modal");
        })
        .catch(error => {
          if (!error.data) {
            alert(this.loc("server-errors.generic-server-error"));
            return;
          }

          if (error.data.invalidRole) alert(this.loc("server-errors.invalid-role"));
          else if (error.data.invalidUsername) alert(this.loc("server-errors.invalid-username"));
          else if (error.data.invalidLogin) alert(this.loc("server-errors.invalid-login"));
          else alert(this.loc("server-errors.unexpected-error"));
        });
    },
    localCheck() {
      if (this.user.userName == "") return { ok: false, message: "emptyUsername" };

      if (!/^[A-Za-z._]+$/.test(this.user.login)) return { ok: false, message: "invalidLogin" };

      if (!/^((\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+))$/.test(this.user.email))
        return { ok: false, message: "invalidEmail" };
      if (this.user.phone == "") return { ok: false, message: "emptyPhoneNumber" };

      return { ok: true, message: null };
    },
    onSubmit(e) {
      e.preventDefault();

      var checkResult = this.localCheck();
      if (!checkResult.ok) {
        if (checkResult.message != null) alert(this.loc("checks." + checkResult.message));

        return;
      }

      this.createUser();
    },
    loc(tag) {
      return this.$t("settings.user-management.user.user-modal." + tag);
    }
  }
};
</script>
