<style scoped>
.viewer-container {
  overflow: hidden;
  height: 79vh;
}
.overflowable {
  overflow: auto;
  overflow-x: hidden;
}
.h-80 {
  height: 80vh;
}
.h-15 {
  height: 15vh;
}
.h-65 {
  height: 65vh;
}
</style>

<template>
  <b-container fluid class="viewer-container">
    <div class="overflowable h-80">
      <div class="overflowable h-15">
        <b-button @click="update" class="mb-2">
          {{ $t("settings.system.base-language-table.save-change-btn") }}
        </b-button>
        <b-checkbox v-model="showOnlyStringsToTranslate" switch>
          {{ $t("settings.system.base-language-table.show-only-string-to-translate") }}
        </b-checkbox>
        <b-form-select
          v-show="showOnlyStringsToTranslate"
          v-model="selectedLanguage"
          :options="languages"
        ></b-form-select>
      </div>
      <div class="overflowable h-65">
        <b-table :fields="fields" :items="items">
          <template v-slot:top-row>
            <td><b-input v-model.trim="filter.id" /></td>
            <td><b-input v-model.trim="filter.en" /></td>
            <td><b-input v-model.trim="filter.ru" /></td>
            <td><b-input v-model.trim="filter.lt" /></td>
          </template>
          <template v-slot:cell(en)="row">
            <b-input :value="row.item.en" @blur="updateString(row.item.id, 'en', $event)" />
          </template>
          <template v-slot:cell(ru)="row">
            <b-input :value="row.item.ru" @blur="updateString(row.item.id, 'ru', $event)" />
          </template>
          <template v-slot:cell(lt)="row">
            <b-input :value="row.item.lt" @blur="updateString(row.item.id, 'lt', $event)" />
          </template>
        </b-table>
      </div>
    </div>
  </b-container>
</template>

<script>
import i18nSettings from "@/mixins/i18n-settings.js";
import comparator from "@/services/string-comparator.js";
import { updateLocaleMessages } from "@/services/locale.js";
import { EN, RU, LT } from "@/const/langs.js";

export default {
  mixins: [i18nSettings],
  data() {
    return {
      filter: { id: "", [RU]: "", [EN]: "", [LT]: "" }
    };
  },
  computed: {
    fields() {
      return [
        { key: "id", label: this.$t("settings.system.ui-language.json-path"), sortable: true },
        ...this.baseFields
      ];
    },
    items() {
      let items = this.$store.getters["i18n/strings"].filter(
        x =>
          comparator.compareRows(x.id, this.filter.id) &&
          comparator.compareRows(x[EN], this.filter[EN]) &&
          comparator.compareRows(x[RU], this.filter[RU]) &&
          comparator.compareRows(x[LT], this.filter[LT])
      );

      return this.filterItemsByNeedToTranslate(items);
    }
  },
  methods: {
    update() {
      this.$store
        .dispatch("i18n/updateUIStrings")
        .then(() => this.$i18n.availableLocales.forEach(x => updateLocaleMessages(this.$i18n, x)))
        .catch(() => alert(this.$t("settings.system.ui-language.error.error-while-update-strings")));
    }
  },
  mounted() {
    this.$store
      .dispatch("i18n/loadUIStrings")
      .catch(() => alert(this.$t("settings.system.ui-language.error.error-while-load-strings")));
  }
};
</script>
