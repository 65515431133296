<template>
  <b-container fluid class="overflowable">
    <b-row>
      <b-col>
        <settings-list />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import SettingsList from "@/components/settings/SettingsList.vue";

export default {
  components: {
    "settings-list": SettingsList
  }
};
</script>
